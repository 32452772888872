import React from "react"
import { Link } from "gatsby"

import Layout from "../../../components/customlayout"
import SEO from "../../../components/seo"
import AirwayHeader from "../../../components/airwayheader"

import styled from "styled-components"

export default function RocuroniumVsSuccinylcholine(props) {
  return (
    <>
      <AirwayHeader></AirwayHeader>
      <AirwayContainer>
        <Layout
          title={
            "Rocuronium Versus Succinylcholine for Rapid Sequence Intubation"
          }
        >
          <SEO
            title={
              "Rocuronium Versus Succinylcholine for Rapid Sequence Intubation"
            }
            description={
              "There are two neuromuscular blocking agents that have short enough onset times to be used in rapid sequence intubation and induction: succinylcholine and rocuronium."
            }
            url={
              "https://www.nucleotype.com/airway/rocuronium-vs-succinylcholine"
            }
          />
          <p>
            There are two neuromuscular blocking agents that have short enough
            onset times to be used in rapid sequence intubation and induction:
            succinylcholine and rocuronium.
          </p>

          <h2>Onset and Duration</h2>
          <p>
            Succinylcholine’s time of onset of paralysis is 45 to 60 seconds and
            a duration of action of 6 to 10 minutes. Rocuronium has a time of
            onset of 45 to 60 seconds with a duration of action of approximately
            45 minutes. Because of the longer duration of action, rocuronium is
            generally not recommended when prolonged neuromuscular blockade is
            not needed or less desired - such as in cases where the clinician
            will need to obtain or repeat a neurological exam in a timely manner
            (traumatic brain injuries, strokes, intracranial hemorrhages, etc.).
          </p>
          <p>
            Succinylcholine has a much faster duration of action over rocuronium
            making it desirable in those neurological cases mentioned above,
            however, there are some side effects and contraindications to
            consider.
          </p>

          <h2>Adverse Effects and Contraindications</h2>
          <p>
            Succinylcholine is absolutely contraindicated in patients with a
            history of strong family history of malignant hyperthermia and in
            those patients who are at risk for severe hyperkalemia.
          </p>
          <p>
            Determining the patients at risk for severe hyperkalemia can be
            determined by suggestive findings on electrocardiography, or when
            history suggests at risk pathology or at-risk medical history. For
            example, fatal cases of hyperkalemia have occurred in patients with
            rhabdomyolysis given succinylcholine. Also pathologies that cause
            upregulation of acetylcholine receptors, increases the risk of
            severe hyperkalemia from succinylcholine in susceptible patients.
            Receptor upregulation can take several days to develop, but once it
            is present hyperkalemia develops within a few minutes after
            succinylcholine is given. Because of these risks, succinylcholine is
            not recommended in the following clinical scenarios:
          </p>

          <ul>
            <li>Malignant hyperthermia history (personal or family)</li>
            <li>Neuromuscular disease</li>
            <li>Muscular dystrophy</li>
            <li>Stroke over 72 hours old</li>
            <li>Rhabdomyolysis</li>
            <li>Burn over 72 hours old</li>
            <li>
              Significant hyperkalemia (eg, suggested by characteristic changes
              on an electrocardiogram)
            </li>
          </ul>
          <h2>Succinylcholine and bradycardia</h2>
          <p>
            Succinylcholine causes nicotinic activation that manifests as muscle
            fasciculations, along with muscarinic stimulation that may lead to
            bradycardia in selected patients. Bradycardia in children is of
            particular importance because of the attribution of succinylcholine
            in causing the bradycardia, although data on this is controversial.
            Pretreatment with atropine may help to prevent this side effect,
            although no large randomized trials have been performed to assess
            this
          </p>
          <StyledLine top />
          <div className="recommended">
            <div className="recommended-label">RECOMMENDED READING</div>
            <RecommendedArticleAnchor href="https://amzn.to/2WEky5N">
              <div className="content-label">AFFILIATE LINK</div>
              <h2>The ICU Book</h2>
              <p>
                A fundamental and respected resource in critical care. Including
                a review of various common critical care pathology, ariway and
                mechnical ventilation.
              </p>
            </RecommendedArticleAnchor>
            <RecommendedArticleContainer to="airway/rsi-indications">
              <div className="content-label">ARTICLE</div>
              <h2>
                Indications and Contraindications to Rapid Sequence Intubation
              </h2>
              <p>
                Indications for RSI are simply those patients who cannot
                ventilate, oxygenate or fail to protect their aiway.
                Contraindications are few and relative.
              </p>
            </RecommendedArticleContainer>
            <RecommendedArticleContainer to="airway/rsi-process">
              <div className="content-label">ARTICLE</div>
              <h2>
                Seven P's of RSI - The Process of Rapid Sequence Intubation
              </h2>
              <p>
                RSI involves the use of an induction agent, followed immediately
                by a neuromuscular blocking agent to quickly create an optimal
                environment for intubation.
              </p>
            </RecommendedArticleContainer>
          </div>
          <StyledLine />
        </Layout>
      </AirwayContainer>
    </>
  )
}

const AirwayContainer = styled.div`
  padding: 0 15px 15px 15px;
  max-width: 1000px;
  margin: auto;
  h3 {
    margin-top: 40px;
  }
  .recommended {
    .recommended-label {
      font-size: 14px;
      margin-top: 0;
      margin-bottom: 35px;
      font-weight: 500;
      font-family: futura-pt, sans-serif;
    }
    .content-label {
      font-family: futura-pt, sans-serif;
      font-size: 12px;
      font-weight: 500;
      line-height: 24px;
      color: gray;
    }
  }
`

const RecommendedArticleAnchor = styled.a`
  text-decoration: none;
  box-shadow: none;
  display: block;
  color: black;
  max-width: 1000px;
  margin: auto;
  margin-bottom: 45px;
  h2 {
    font-size: 21px;
    margin-top: 0;
    margin-bottom: 8px;
  }
  p {
    font-size: 18px;
    line-height: 130%;
    margin-top: 0;
  }
`

const RecommendedArticleContainer = styled(Link)`
  text-decoration: none;
  box-shadow: none;
  display: block;
  color: black;
  max-width: 1000px;
  margin: auto;
  margin-bottom: 45px;
  h2 {
    font-size: 21px;
    margin-top: 0;
    margin-bottom: 8px;
  }
  p {
    font-size: 18px;
    line-height: 130%;
    margin-top: 0;
  }
`

const StyledLine = styled.hr`
  margin-top: ${props => (props.top ? "100px" : "50px")};
`
